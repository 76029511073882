import React, { useState } from 'react'
// import PropTypes from 'prop-types';
import Box from '../reusecore/src/elements/Box';
import Text from '../reusecore/src/elements/Text';
import Heading from '../reusecore/src/elements/Heading';
import Button from '../reusecore/src/elements/Button';
import Container from '../common/src/components/UI/Container';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Form } from 'react-bootstrap'


import NewsletterWrapper, { ContactFormWrapper } from './newsletter.style';

const Newsletter = ({
  sectionWrapper,
  textArea,
  buttonArea,
  buttonStyle,
  title,
  description,
}) => {

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  // const [newsletterWarningMsg, setNewsletterWarningMsg] = useState('')
  // const [sendButtonMsg, setSendButtonMsg] = useState('Subscribe')
  const [newsletterSuccessMsg, setNewsletterSuccessMsg] = useState('')
  // const [disabled, disableButton] = useState(false);

  const _handleSubmit = async e => {
    e.preventDefault()
    // console.log('SUBMITTED!', name, email)

    const res = await addToMailchimp(email, { "NAME": name})
    if (res.result === 'error') {
        setNewsletterSuccessMsg(`${email} is already subscribed to list.`)
    } else {
        setNewsletterSuccessMsg('Subscription Successful. Thanks!');
        // setSendButtonMsg('Subscribed');
        setName('')
        setEmail('')
        // disableButton(true);
    }

    // setTimeout(() => {
    //     setNewsletterSuccessMsg('')
    //     // setName('')
    //     // setEmail('')
    //     // setSendButtonMsg('Subscribe');
    //     // disableButton(false);
    // }, 5000)        
  }

  const emailHandle = e => {
    e.preventDefault()
    setEmail(e.target.value)
  }

  const nameHandle = e => {
    e.preventDefault()
    setName(e.target.value)
  }

  return (
    <Box {...sectionWrapper} as="section">
      <NewsletterWrapper>
        <Container>
          <Box {...textArea}>
            <Heading content="Subscribe to our newsletter" {...title} />
            <Text
              content="Subscribe to stay updated on the latest Cassandra developments and resources!"
              {...description}
            />
          </Box>
          <Box {...buttonArea}>
            <ContactFormWrapper>
              <Form onSubmit={_handleSubmit} style={{ width: '100%' }}>
                {/* <Input
                inputType="email"
                label="Email address"
                iconPosition="right"
                isMaterial={true}
                className="email_input"
                onChange={emailHandle}
              />
              <Input
                inputType="text"
                label="Full Name"
                iconPosition="right"
                isMaterial={true}
                className="email_input"
                onChange={nameHandle}
              /> */}
                <Form.Group controlId="validationCustom01">
                  <Form.Control required type="text" value={name} onChange={nameHandle} placeholder="Full Name" />
                </Form.Group>
                <Form.Group controlId="formBasicEmail">
                  <Form.Control required type="email" value={email} onChange={emailHandle} placeholder="Email" />
                </Form.Group>
                <Button {...buttonStyle} title="Get access" type="submit" />
              </Form>
            </ContactFormWrapper>
          </Box>
        </Container>
      </NewsletterWrapper>
    </Box>
  );
};

// Newsletter.propTypes = {
//   sectionWrapper: PropTypes.object,
//   textArea: PropTypes.object,
//   buttonArea: PropTypes.object,
//   buttonStyle: PropTypes.object,
//   title: PropTypes.object,
//   description: PropTypes.object,
// };

Newsletter.defaultProps = {
  sectionWrapper: {},
  textArea: {
    mb: ['40px', '40px', '40px', '0', '0'],
    pr: ['0', '0', '0', '80px', '0px'],
  },
  title: {
    fontSize: ['18px', '20px', '22px', '24px', '26px'],
    fontWeight: '500',
    color: '#fff',
    lineHeight: '1.34',
    mb: ['14px', '14px', '14px', '14px', '13px'],
    textAlign: ['center', 'center', 'center', 'center', 'center'],
    letterSpacing: '-0.025em',
  },
  description: {
    fontSize: ['14px', '14px'],
    maxWidth: ['100%', '400px'],
    fontWeight: '400',
    color: '#fefefe',
    lineHeight: '1.7',
    mb: 15,
    textAlign: ['center', 'center', 'center', 'center', 'center'],
  },
  buttonArea: {
    zIndex: 1,
  },
  buttonStyle: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '700',
    pl: '30px',
    pr: '30px',
  },
};

export default Newsletter;
