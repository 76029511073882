import React from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            {'Copyright © '}
            <Link color="inherit" href="https://anant.us">
                Anant
        </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

export default function Footer() {

    return (
        <footer className="footer">
            <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
                © 2020 Anant Corporation, All Rights Reserved.
        </Typography>
            <Copyright />
        </footer>
    );
}
